var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.galleryImageURL)?_c('Gallery',{attrs:{"oncontextmenu":"return false;","imageURL":_vm.galleryImageURL,"imageDesc":_vm.galleryImageDesc,"badgeNames":_vm.badges,"location":_vm.location,"unsplashID":_vm.unsplashID,"downloadURL":_vm.downloadURL},nativeOn:{"click":function($event){_vm.galleryImageURL = null
      _vm.galleryImageDesc = null
      _vm.badges = null
      _vm.location = undefined
      _vm.unsplashID = undefined
      _vm.downloadURL = undefined}}}):_vm._e(),_c('div',{staticClass:"container-xxl"},[_c('div',{staticClass:"page"},[_c('div',{staticClass:"row mt-5 gy-2"},[_c('div',{staticClass:"col-xs-12 col-md-9"},[_c('router-link',{attrs:{"to":"/"}},[_c('h1',{staticClass:"site-name d-inline-block"},[_vm._v("Ethan's Photo Gallery")])])],1),_vm._m(0)]),_c('div',{staticClass:"mt-3"},_vm._l((this.categories),function(category,id){return _c('router-link',{key:id,staticClass:"category-item",class:{ 'category-item-selected': _vm.currentCategoryID == id },attrs:{"to":id}},[_vm._v(_vm._s(category.title))])}),1),_vm._l((_vm.getSections(_vm.currentCategoryID)),function(section,index){return _c('div',{key:index},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-content-end mt-5 mb-3"},[_c('h2',{staticClass:"section-title"},[_vm._v(_vm._s(section.title.replace(/=/g, ' ')))])])]),_c('div',{staticClass:"row g-3"},_vm._l((section.images),function(image,index){return _c('div',{key:index,staticClass:"col-xs-12 col-sm-6 col-lg-4 col-xxl-3",attrs:{"oncontextmenu":"return false;"},on:{"click":function($event){return _vm.clickImage(image)}}},[_c('Frame',{attrs:{"image":image}})],1)}),0)])}),_c('footer',{staticClass:"row my-4"},[_c('div',{staticClass:"col text-center"},[_vm._v(" © "+_vm._s(new Date().getFullYear())+" "),_c('a',{attrs:{"href":"mailto:e1hanw0ng@gmail.com","title":"e1hanw0ng@gmail.com"}},[_vm._v("Ethan Wong")]),_vm._v(" | "),_c('a',{attrs:{"href":"https://github.com/GetToSet/ethanwong-photo-gallery/","title":"GitHub"}},[_vm._v("GitHub")])])])],2)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-xs-12 col-md-3 d-flex flex-row-reverse align-items-center"},[_c('a',{staticClass:"nav-item",attrs:{"href":"https://ethanwong.me","title":"Home","target":"_blank"}},[_vm._v("Home")]),_c('a',{staticClass:"nav-item",attrs:{"href":"https://unsplash.com/@ethanwong/","title":"Unsplash","target":"_blank"}},[_vm._v("Unsplash")]),_c('a',{staticClass:"nav-item",attrs:{"href":"https://www.instagram.com/e1hanw0ng/","title":"Instagram","target":"_blank"}},[_vm._v("Instagram")])])
}]

export { render, staticRenderFns }